import { useRouter } from "next/router";

import AppLayout from "@/components/Layouts/App/AppLayout";
import PageLoading from "@/components/PageLoading";
import { useAuthRouteProtection } from "@/utils/hookUtils";

const AppPage = () => {
  const router = useRouter();

  const handleLoggedOutCallback = () => {
    return router.replace("/login");
  };

  useAuthRouteProtection({
    isRouteProtected: false,
    onLoggedOutCallback: handleLoggedOutCallback,
  });

  return <PageLoading />;
};

AppPage.getLayout = (page) => <AppLayout> {page}</AppLayout>;

export default AppPage;
